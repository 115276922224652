import React, {useState} from 'react';
import cls from './CardPage.module.scss'
import {apiAxiosInstance, Button} from "@supermegapay/common";
import {v4 as uuidv4} from "uuid";

const defaultFormFields = {
  amount: '',
  card_number: '',
  mm: '',
  yy: '',
  cvc: '',
}

const CardPage = () => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [formFields, setFormFields] = useState(defaultFormFields);
  const {amount, card_number, mm, yy, cvc} = formFields;

  const onChangeInput = (e) => {
    const {name, value} = e.target

      setFormFields({
        ...formFields,
        [name]: value
      })

  }

  const onBlurAmount = (e) => {
    const {name, value} = e.target

    const newValue = Math.max(1200, Math.min(9999999, Number(value)));
    setFormFields({
      ...formFields,
      [name]: newValue
    })
  }

  const onSubmitForm = async (e) => {
    e.preventDefault()
    setButtonDisable(true)

    try {
      const {data} = await apiAxiosInstance.post("/api/orders", {
        amount: amount.toString(),
        card_number,
        mm,
        yy,
        cvc,
        service_id: "megacom-kg",
        user_ref: uuidv4()
      });
      console.log(data)

      if (data.form) {
        const form2wrap = document.createElement("div");
        form2wrap.innerHTML = data.form;
        document.body.appendChild(form2wrap);
        form2wrap.getElementsByTagName("form")[0].submit();
      }

    } catch (e) {
      console.log(e)
      setResultMessage(e.message)
    }

    setButtonDisable(false)
  }

  return (
    <div className={cls.CardPage}>
      <h2>Оплата банковской картой</h2>
      <form className={cls.form}>
        <div className={cls.field}>
          <label htmlFor="amount">Сумма</label>
          <input className={cls.input} required id="amount" name="amount" type="number" value={amount} onChange={onChangeInput}
                 min={1200} max={999999} onBlur={onBlurAmount}/>
        </div>
        <div className={cls.field}>
          <label htmlFor="card_number">Номер карты</label>
          <input className={cls.input} id="card_number" name="card_number" type="number" value={card_number}
                 onChange={onChangeInput} required/>
        </div>
        <div className={cls.field}>
          <label htmlFor="mm">Месяц окончания действия</label>
          <input className={cls.input} id="mm" name="mm" type="number" value={mm} onChange={onChangeInput} required/>
        </div>
        <div className={cls.field}>
          <label htmlFor="yy">Год окончания действия</label>
          <input className={cls.input} id="yy" name="yy" type="number" value={yy} onChange={onChangeInput} required/>
        </div>
        <div className={cls.field}>
          <label htmlFor="cvc">CVC/CVV код</label>
          <input className={cls.input} id="cvc" name="cvc" type="password" value={cvc} onChange={onChangeInput}
                 maxLength={3} required/>
        </div>
        <p>Валюта: <b>RUB</b></p>
        {
          resultMessage && <div className={cls.resultText}>{resultMessage}</div>
        }
        <div>
          <Button
            type="submit"
            onClick={onSubmitForm}
            className={cls.button}
            isLoading={buttonDisable}
            disabled={buttonDisable}
          >
            Оплатить
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CardPage;
