import CardPage from "./pages/Card/CardPage";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <main className="main-content">
        <CardPage/>
      </main>
    </div>
  );
}

export default App;
